<template>
  <div class="container">
    <el-breadcrumb separator-class="el-icon-arrow-right" style="height: 40px;">
      <el-breadcrumb-item>{{
        fun.getLanguageText($store.state.language, "设备密码更改")
      }}</el-breadcrumb-item>
    </el-breadcrumb>
    <el-form
      ref="ruleForm"
      :model="formData"
      :rules="rules"
      label-width="200px"
      status-icon
    >
      <el-form-item
        :label="fun.getLanguageText($store.state.language, '客户名称')"
      >
        <el-select
          v-model="customer"
          :placeholder="
            fun.getLanguageText($store.state.language, '请选择客户名称')
          "
          @change="changeCustomer"
        >
          <el-option
            v-for="item in customerList"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item
        :label="fun.getLanguageText($store.state.language, '终端客户名称')"
      >
        <el-select
          v-model="client"
          :placeholder="
            fun.getLanguageText($store.state.language, '请选择终端客户名称')
          "
          @change="changeClient"
        >
          <el-option
            v-for="item in clientList"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item
        :label="fun.getLanguageText($store.state.language, '设备类型')"
      >
        <el-select
          v-model="deviceTypeId"
          :placeholder="
            fun.getLanguageText($store.state.language, '请选择设备类型')
          "
          @change="changeDeviceType"
        >
          <el-option
            v-for="item in deviceTypeList"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item
        :label="fun.getLanguageText($store.state.language, '设备名称')"
      >
        <el-select
          v-model="deviceNameId"
          :placeholder="
            fun.getLanguageText($store.state.language, '请选择设备名称')
          "
          @change="changeDeviceName"
        >
          <el-option
            v-for="item in deviceNameList"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item
        :label="fun.getLanguageText($store.state.language, '设备型号')"
      >
        <el-select
          v-model="deviceModelId"
          :placeholder="
            fun.getLanguageText($store.state.language, '请选择设备型号')
          "
        >
          <el-option
            v-for="item in deviceModelList"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item
        prop="sn"
        :label="fun.getLanguageText($store.state.language, '设备序列号')"
      >
        <el-select
          v-model="formData.sn"
          :placeholder="
            fun.getLanguageText($store.state.language, '请选择设备序列号')
          "
        >
          <el-option
            v-for="item in snList"
            :key="item.sn"
            :label="item.sn"
            :value="item.sn"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item
        prop="secret"
        :label="fun.getLanguageText($store.state.language, '旧密码/初始密码')"
      >
        <el-input
          v-model="formData.secret"
          :placeholder="
            fun.getLanguageText($store.state.language, '请输入旧密码/初始密码')
          "
          type="password"
        ></el-input>
      </el-form-item>
      <el-form-item
        prop="newSecret"
        :label="fun.getLanguageText($store.state.language, '新密码')"
      >
        <el-input
          v-model="formData.newSecret"
          :placeholder="
            fun.getLanguageText($store.state.language, '请输入密码')
          "
        ></el-input>
      </el-form-item>
      <el-form-item
        prop="confirmSecret"
        :label="fun.getLanguageText($store.state.language, '确认新密码')"
      >
        <el-input
          v-model="formData.confirmSecret"
          :placeholder="
            fun.getLanguageText($store.state.language, '请再次输入新密码')
          "
        ></el-input>
      </el-form-item>
      <el-form-item>
        <el-button type="info" @click="$router.push('/Home')"
          >{{ fun.getLanguageText($store.state.language, "取消") }}
        </el-button>
        <el-button type="primary" @click="sub"
          >{{ fun.getLanguageText($store.state.language, "确认") }}
        </el-button>
      </el-form-item>
    </el-form>
  </div>
</template>
<script>
export default {
  data() {
    const validatePass = (rule, value, callback) => {
      if (value === "") {
        callback(
          new Error(
            this.fun.getLanguageText(
              this.$store.state.language,
              "请再次输入密码"
            )
          )
        );
      } else if (this.formData.confirmSecret !== this.formData.newSecret) {
        callback(
          new Error(
            this.fun.getLanguageText(
              this.$store.state.language,
              "两次输入密码不一致"
            )
          )
        );
      } else {
        callback();
      }
    };
    return {
      formData: {
        sn: "", // 设备码
        secret: "", // 密码
        newSecret: "", // 新密码
        confirmSecret: "", // 确认密码
      },
      customerList: [], // 客户列表
      customer: "", // 当前选中客户
      clientList: [], // 终端客户列表
      client: "", // 当前选中终端客户
      deviceTypeList: [], // 设备类型列表
      deviceTypeId: "",
      deviceNameList: [], // 设备名称列表
      deviceNameId: "",
      deviceModelList: [], // 设备型号列表
      deviceModelId: "",
      snList: [], // 设备码列表
      rules: {
        customerId: [
          {
            required: true,
            message: this.fun.getLanguageText(
              this.$store.state.language,
              "客户不能为空"
            ),
            trigger: "change",
          },
        ],
        clientId: [
          {
            required: true,
            message: this.fun.getLanguageText(
              this.$store.state.language,
              "终端用户不能为空"
            ),
            trigger: "change",
          },
        ],
        sn: [
          {
            required: true,
            message: this.fun.getLanguageText(
              this.$store.state.language,
              "设备序列号不能为空"
            ),
            trigger: "change",
          },
        ],
        secret: [
          {
            required: true,
            message: this.fun.getLanguageText(
              this.$store.state.language,
              "原始密码不能为空"
            ),
            trigger: "blur",
          },
        ],
        newSecret: [
          {
            required: true,
            message: this.fun.getLanguageText(
              this.$store.state.language,
              "新密码不能为空"
            ),
            trigger: "blur",
          },
        ],
        confirmSecret: [
          {
            required: true,
            message: this.fun.getLanguageText(
              this.$store.state.language,
              "确认新密码不能为空"
            ),
            trigger: "blur",
          },
          {
            validator: validatePass,
            trigger: "blur",
          },
        ],
      },
    };
  },
  created() {
    this.getSelectCustomer();
    this.getDeviceTypeList();
  },
  methods: {
    // 修改密码
    async changePwd() {
      let res = await this.$http.post("device/change-pwd", this.formData);
      if (res.data.code === 2000) {
        this.$message.success(
          this.fun.getLanguageText(this.$store.state.language, "修改密码成功")
        );
        return;
      }
      this.$message.warning(
        this.fun.getLanguageText(this.$store.state.language, "修改密码失败")
      );
    },
    // sub
    sub() {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          this.changePwd();
          console.log(this.formData);
          // alert('执行sub指令!');
        } else {
          console.log("error submit!!!");
          return false;
        }
      });
    },
    // 客户名称被修改
    changeCustomer() {
      this.getSelectClient();
      this.client = "";
      this.deviceNameId = "";
      this.deviceModelId = "";
      this.formData.sn = "";
      this.getSNList();
    },
    // 客户端用户被修改
    changeClient() {
      this.deviceNameId = "";
      this.deviceModelId = "";
      this.formData.sn = "";
      this.getSNList();
    },
    // 设备类型被修改
    changeDeviceType() {
      this.getDeviceNameList();
      this.deviceNameId = "";
      this.deviceModelId = "";
      this.formData.sn = "";
      this.getSNList();
    },
    // 设备名称被修改
    changeDeviceName() {
      this.getDeviceModelList();
      this.deviceModelId = "";
      this.formData.sn = "";
      this.getSNList();
    },
    // 设备序列号被修改
    changeSn() {},
    // 读取客户列表
    async getSelectCustomer() {
      let res = await this.$http.post("user/select-customer", {});
      let { data } = res;
      if (data.code !== 2000) return;
      this.customerList = data.data;
    },
    // 读取终端客户列表
    async getSelectClient() {
      let res = await this.$http.post("user/select-client", {
        pid: this.customer,
      });
      let { data } = res;
      if (data.code !== 2000) return;
      this.clientList = data.data;
    },
    // 读取设备类型列表
    async getDeviceTypeList() {
      let res = await this.$http.post("device-type/list", {
        type: 1,
      });
      let { data } = res;
      if (data.code !== 2000) return;
      this.deviceTypeList = data.data;
    },
    // 读取设备名称列表
    async getDeviceNameList() {
      let res = await this.$http.post("device-type/list", {
        type: 2,
        pid: this.deviceTypeId,
      });
      let { data } = res;
      if (data.code !== 2000) return;
      this.deviceNameList = data.data;
    },
    // 读取设备型号列表
    async getDeviceModelList() {
      let res = await this.$http.post("device-type/list", {
        type: 3,
        pid: this.deviceNameId,
      });
      let { data } = res;
      if (data.code !== 2000) return;
      this.deviceModelList = data.data;
    },
    // 读取设备序列号列表
    async getSNList() {
      let res = await this.$http.post("device/dropdown-list", {
        deviceTypeId: this.deviceTypeId,
        deviceNameId: this.deviceNameId,
        deviceModelId: this.deviceModelId,
        customerId: this.customer,
        clientId: this.client,
      });
      let { data } = res;
      if (data.code !== 2000) return;
      this.snList = data.data;
      console.log(this.snList);
    },
  },
};
</script>

<style lang="less" scoped>
.el-form-item {
  .el-input,
  .el-select {
    width: 500px;
  }

  .el-button {
    width: 200px;
    margin-right: 90px;
  }
}
</style>
